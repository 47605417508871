jQuery(function($) {

    gsap.registerPlugin(ScrollTrigger);

    /* animation header */

    CustomEase.create("custom", "M0,0 C0.798,0 0.202,1 1,1 ");

    ScrollTrigger.saveStyles(".header .question, .header .question span");

    ScrollTrigger.matchMedia({

        "(min-width: 1000px)": function() {

            var questions = gsap.utils.toArray(".header .question");

            var masterTimeline = gsap.timeline({
                repeat: -1,
                scrollTrigger: {
                    trigger: ".header",
                    start: "-20% top",
                    end: "bottom top",
                    toggleActions: "play pause resume reset",
                    //markers: true,
                    scrub: false
                }
            });

            questions.forEach((question, i) => {
                var lines = gsap.utils.toArray(question.children);
                var questionTimeline = gsap.timeline();

                lines.forEach((line, i) => {

                    var lineTimeline = gsap.timeline();

                    lineTimeline.from(line.childNodes, {delay: .3, autoAlpha: 0})
                                .to( line.childNodes, { duration: 2, backgroundPosition: "-101% 100%", ease: "custom" }, '<')
                                .to( line.childNodes, { color: 'white', duration: 0 }, '<1')

                    questionTimeline.add(lineTimeline, "-=1.5");
                });

                questionTimeline.to(lines, {delay: 2, autoAlpha: 0, y: -10});

                masterTimeline.add(questionTimeline);
            });
        },

        "(max-width: 999px)": function() {
            var timeline = gsap.timeline({
                repeat: -1,
                defaults: {
                    duration: .5,
                    ease: "power4.out"
                },
                scrollTrigger: {
                    trigger: ".header",
                    start: "-20% top",
                    end: "bottom top",
                    toggleActions: "play pause resume reset",
                    //markers: true,
                    scrub: false
                }
            });

            let questions = gsap.utils.toArray(".header .question");

            questions.forEach((question, i) => {
                timeline.from(question, {delay: .3, autoAlpha: 0, y: 10})
                        .to(question, {delay: 1.5, autoAlpha: 0, y: -20});
            });
        }

    });

    /* animation logos */

    ScrollTrigger.saveStyles(".logoList .image");

    ScrollTrigger.matchMedia({

        "(min-width: 500px)": function() {
            gsap.from(".logoList .image", {
                duration: 1,
                scale: 0.3,
                ease: "back.out(1.7)",
                y: 50,
                autoAlpha: 0,
                stagger: {
                    amount: .5,
                },
                scrollTrigger: {
                    trigger: ".logoList",
                    //scrub: .5,
                    start: "top bottom",
                    end: "bottom top",
                    toggleActions: "play reset play reset",
                    //markers: true
                }
            });
        },

        "(max-width: 499px)": function() {
            gsap.from(".logoList .image", {
                duration: 1,
                scale: 0.3,
                ease: "back.out(1.7)",
                y: 50,
                autoAlpha: 0,
                stagger: .5,
                scrollTrigger: {
                    trigger: ".logoList",
                    //scrub: 2,
                    start: "top bottom",
                    end: "bottom top",
                    toggleActions: "play reset play reset",
                    //markers: true
                }
            });
        }

    });


    /* animation textThreeCols */

    ScrollTrigger.saveStyles(".textThreeCols h3, .textThreeCols p");

    ScrollTrigger.matchMedia({
        "(min-width: 900px)": function() {
            var timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".textThreeCols",
                    start: "top 80%",
                    end: "bottom top",
                    toggleActions: "play reset play reset",
                    //markers: true,
                    scrub: false
                }
            });

            let cols = gsap.utils.toArray(".textThreeCols .col");

            cols.forEach((col, i) => {
                timeline.from(col.querySelector('h3'), {
                    ease: "back.out(1.7)",
                    x: -80,
                    autoAlpha: 0,
                });

                timeline.from(col.querySelector('p'), {
                    ease: "back.out(1.7)",
                    x: -80,
                    autoAlpha: 0,
                }, "<0.1");
            });
        },

        "(max-width: 899px)": function() {
            let cols = gsap.utils.toArray(".textThreeCols .col");

            cols.forEach((col, i) => {

                var timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: col,
                        start: "top 80%",
                        end: "bottom 80%",
                        //markers: true,
                        scrub: true
                    }
                });

                timeline.from(col.querySelector('h3'), {
                    ease: "back.out(1.7)",
                    x: -50,
                    autoAlpha: 0,
                });

                timeline.from(col.querySelector('p'), {
                    ease: "back.out(1.7)",
                    x: -50,
                    autoAlpha: 0,
                }, "<0.1");

            });
        }

    });

    /* animation callToActionPhone */

    gsap.from(".callToActionPhone .phone", {
        scrollTrigger: {
            trigger: ".callToActionPhone",
            scrub: .5,
            start: "-20% 80%",
            end: "center 80%",
            //markers: true
        },
        scale: 0.3,
        y: -10,
        ease: "rough",
        autoAlpha: 0,
    });


    /* animation form element */

    let formTimeline = gsap.timeline({
        scrollTrigger: {
            trigger: ".form",
            start: "top bottom",
            end: "bottom top",
            toggleActions: "play reset play reset",
            //markers: true
        }
    });

    formTimeline.from(".form .wpcf7-form", {delay: .5, autoAlpha: 0, scale: 0.8, y: 100, duration: "1"}, "<")
                .from(".form .fieldWrapper", {duration: .7, autoAlpha: 0, y:10, stagger: .2}, "<");

});
