/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function($) {

    /* CookieBanner aktivieren */
    //netCookie.init();

    // Select all links with hashes
    $('a[href*="#"]')
      // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                      scrollTop: target.offset().top
                }, 1000, function() {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });


    // show header if user scrolls up
    var options = {
        // vertical offset in px before element is first unpinned
        offset : 150,
        tolerance : {
            up : 10,
            down : 0
        },
        /*onUnpin : function() {
            if ($('.callOverlay').length) {
                $('.callOverlay').removeClass('visible');
            }
        },*/
    };

    var headroom  = new Headroom(document.querySelector("header"), options);

    headroom.init();

    // header questions animation

    var tl = gsap.timeline();

    tl.to('.question:after', {duration: 2})


});
